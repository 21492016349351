<template>
    <a-layout>
        <a-layout-sider :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <!-- <a-layout-header style="background: #fff; padding: 0">
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
                <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
            </a-layout-header> -->
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <a-select v-model:value="cate" placeholder="{{cate}}">
                    <a-select-option value="广告主">广告主</a-select-option>
                    <a-select-option value="运营">运&emsp;营</a-select-option>
                    <a-select-option value="流量">流&emsp;量</a-select-option>
                </a-select>
                <button @click="meatamask">按钮</button>
                <!-- <span v-show="acountaddress">this you address? <font style="color: red;">{{ acountaddress }}</font>
                </span> -->

            </a-layout-content>
            <!-- <center>
                <h1>
                    网站已备案
                <a href="https://beian.miit.gov.cn/" target="blank">京ICP备2022026867号-1</a>
            </h1></center> -->
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
import { ethers } from "ethers"
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            email: '',
            pwd: '',
            code: '',
            // 地址
            accountaddress: '',
            signature: '',
            cate: "广告主",
            cate_data: { "广告主": "1", "运营": "2", "流量": "3" }
        }
    },
    methods: {

        check_sign() {
            console.log(this.cate_data[this.cate]);
            this.myaxios(this.weburl + '/reg/', 'put', { 'account_address': this.accountaddress, "signature": this.signature, 'cate': this.cate_data[this.cate] }).then((res) => {
                console.log(res);
                if (res.errcode == 0) {
                    this.$message.info(res.msg)
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('phone', res.token)
                }
                else {
                    this.$message.info(res.msg)
                }

            });
        },

        meatamask() {
            var that = this
            ethereum.enable().then(function () {
                var provider = new ethers.providers.Web3Provider(web3.currentProvider);

                // 上连
                provider.getNetwork().then(function (res) {
                    console.log("123123", res);
                    if (res['chainId'] != 1) {
                        alert("请更换区块链")

                        return false
                    }
                    provider.listAccounts().then(function (result) {
                        console.log(result[0]);
                        that.accountaddress = result[0]
                        // 获取签名
                        var signer = provider.getSigner()
                        // 获取秒级时间戳
                        var rightnow = (Date.now() / 1000).toFixed(0)
                        console.log("rightnow====>", rightnow);
                        // 时间差
                        var sortanw = rightnow - (rightnow % 600)
                        // 生成签名
                        console.log("sortanw====>", sortanw);

                        signer.signMessage(
                            "Signing in to " + document.domain + " at " + sortanw, that.accountaddress, "test password!"
                        ).then((signature) => {
                            // accountaddress
                            console.log(signature, that.accountaddress);
                            that.signature = signature
                            that.check_sign()
                        })

                    })
                })
            });

        },

        Login() {
            this.myaxios(this.weburl + '/user/login/', 'get', { 'email': this.email, 'pwd': this.pwd }).then((res) => {
                console.log(res);
                if (res.errcode == 0) {
                    this.$toast.success(res.msg);
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('email', res.email)
                    sessionStorage.setItem('token', res.token)
                    this.$router.push({ name: 'Index' })
                } else {
                    this.$toast.fail(res.msg);
                }
            }).catch((err) => {
                console.log(err);
            })
        },
    },
    mounted() {
        // this.myaxios('http://127.0.0.1:8000/weibo_back/', 'post').then(res => {
        //     xinlang_url = res.url
        // })
    },
    created() { }
}
</script>

<style scoped>
@import url('../assets/style.css');
</style>